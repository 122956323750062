import React, { ReactElement } from 'react';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function ViewLoading(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
