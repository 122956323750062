import Stripe from 'stripe';
import { getSubscriptionLevelsData } from './constants';
import { SubscriptionLevel, SubscriptionStatus } from 'types';
import { max } from 'lodash';

export const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export const cleanStripeSubscriptionStatus = (
  status: Stripe.Subscription.Status | undefined,
): SubscriptionStatus => {
  if (!status) return 'unsubscribed';
  else if (status === 'active' || status === 'incomplete' || status === 'trialing') return 'active';
  else if (status === 'past_due') return 'past_due';
  else if (status === 'canceled' || status === 'incomplete_expired' || status === 'unpaid')
    return 'canceled';
  else return 'canceled';
};

export const getSubscriptionLevelFromPrice = (prices: Stripe.Price[]): SubscriptionLevel => {
  const subscriptionLevelsData = getSubscriptionLevelsData(
    process.env.NEXT_PUBLIC_ENV === 'production',
  );

  const levels = prices.map(price => {
    return (
      subscriptionLevelsData.find(subscriptionLevel => {
        return (
          subscriptionLevel.price_id === price.id ||
          subscriptionLevel.prior_price_ids.includes(price.id)
        );
      })?.level || 0
    );
  });

  return max(levels) || 0;
};
