import React, { useState, ReactElement, useRef, useEffect, useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { useQuery } from '@apollo/client';
import { isEmpty, map, head } from 'lodash';

import { Card, Button, Typography, Dialog, DialogActions, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import type { Firestore, SubscriptionStatus } from 'types';
import { formatter } from 'lib/utils';
import { Context } from '../pages/_app';
import ViewLoading from './view-loading';
import { GetCurrentAssignedProductsDocument } from 'functions/types/graphql/firestore';

import type { ResultOf } from '@graphql-typed-document-node/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  card: {
    marginTop: '2rem',
    padding: '2rem',
  },
  products: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  visitShop: {
    padding: '1rem',
    height: '100%',
    width: '100%',
    flex: 1,
  },
});

interface Props {
  variant?: 'summary';
  userId?: string;
}

type CurrentAssignProductsProduct = ResultOf<
  typeof GetCurrentAssignedProductsDocument
>['user']['assigned_products']['products'][0];

export default function Shop({ variant, userId }: Props): ReactElement {
  const classes = useStyles();
  const { cartBag, uid, isAuthenticated } = useContext(Context);
  const fetchId = userId ? userId : uid;
  const { data } = useQuery(GetCurrentAssignedProductsDocument, {
    variables: { id: fetchId },
    skip: !isAuthenticated,
    fetchPolicy: 'cache-and-network',
  });

  const [openModal, setOpenModal] = useState<CurrentAssignProductsProduct>();
  const { enqueueSnackbar } = useSnackbar();

  if (!data) return <ViewLoading />;

  const user = data?.user;
  const subscription_status = user?.subscription_status as SubscriptionStatus;
  const assigned_products = user?.assigned_products;

  const hasAssignedProducts =
    assigned_products && assigned_products.id && assigned_products.products?.length > 0;

  const _closeModal = () => setOpenModal(undefined);
  const _addToCart = (product: Firestore.AssignedProduct) => {
    cartBag.add(product);
    _closeModal();
    enqueueSnackbar('Added to Cart!', { variant: 'success' });
  };

  if (variant === 'summary') {
    if (subscription_status === 'active') {
      if (hasAssignedProducts) {
        return (
          <div className={classes.visitShop}>
            <Typography variant="body1" paragraph>
              Please visit the shop to view your recommended products!
            </Typography>
            <br />
            <Link href="/recommended-products" passHref>
              <Button color="primary" variant="contained">
                Visit Shop
              </Button>
            </Link>
          </div>
        );
      } else {
        return (
          <div className={classes.visitShop}>
            <Typography variant="body1" paragraph>
              Our team is hard at work, your recommended products will be available soon!
            </Typography>
            <Typography variant="body1" paragraph>
              In the meantime, check out your personalised tips and further reading.
            </Typography>
            <br />
            <Link href="/tips" passHref>
              <Button color="primary" variant="contained">
                Tips
              </Button>
            </Link>
          </div>
        );
      }
    } else if (subscription_status === 'past_due') {
      return (
        <div className={classes.visitShop}>
          <Typography variant="body1" paragraph>
            You have an overdue payment on your account, please edit your subscription to fix this!
          </Typography>
          <br />
          <Link href="/subscription" passHref>
            <Button color="primary" variant="contained">
              Subscriptions
            </Button>
          </Link>
        </div>
      );
    } else {
      return (
        <div className={classes.visitShop}>
          <Typography variant="body1" paragraph>
            Based on your results from the Fact-Finder, we recommend that you sign up to:
          </Typography>
          <Typography variant="h6" paragraph>
            Level {user?.classification.level} SkinKitz Subscription.
          </Typography>
          <br />
          <Link href="/subscription" passHref>
            <Button color="primary" variant="contained">
              Subscriptions
            </Button>
          </Link>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5">Recommended Products</Typography>

      {assigned_products ? (
        <Card variant="outlined" className={classes.card}>
          <Typography variant="body1">
            Hey {user.first_name}, here&apos;s what we recommend for you based on your answers.
          </Typography>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.card}>
          <Typography variant="body1" paragraph>
            Hey {user.first_name}, you currently have no recommended products. Please ensure all
            your tasks have been completed.
          </Typography>
          <Typography variant="body1">
            A SkinKitz dermatologist will then review your patient notes to recommend the most
            appropriate products for you. These will be then displayed here for purchase! We will
            alert you when your products are available; please allow 2 business days.
          </Typography>
        </Card>
      )}

      {!isEmpty(assigned_products?.products) && (
        <Card variant="outlined" className={classes.card}>
          <div className={classes.products}>
            {map(assigned_products?.products, product => {
              return (
                <ProductCard
                  key={product.id}
                  product={product}
                  viewProduct={() => setOpenModal(product)}
                />
              );
            })}
          </div>
        </Card>
      )}

      <Dialog open={Boolean(openModal)} onClose={_closeModal} fullWidth maxWidth="lg">
        <DialogActions>
          <IconButton onClick={_closeModal}>
            <Close />
          </IconButton>
        </DialogActions>
        {openModal && <ProductDetails product={openModal} handleAddToCart={_addToCart} />}
      </Dialog>
    </div>
  );
}

const useProductStyles = makeStyles(theme => ({
  root: {
    padding: ({ variant }: { variant: 'summary' }) => (variant === 'summary' ? '0' : '2rem'),
    width: ({ variant }: { variant: 'summary' }) => (variant === 'summary' ? '100%' : '25%'),
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      width: ({ variant }: { variant: 'summary' }) => (variant === 'summary' ? '100%' : '33%'),
    },
    [theme.breakpoints.down('sm')]: {
      width: ({ variant }: { variant: 'summary' }) => (variant === 'summary' ? '100%' : '50%'),
    },
    [theme.breakpoints.down('xs')]: {
      width: ({ variant }: { variant: 'summary' }) => (variant === 'summary' ? '100%' : '100%'),
    },
  },
  title: {
    fontWeight: 700,
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  price: {
    fontSize: '110%',
    marginBottom: '1rem',
  },
}));

interface CardProps {
  product: CurrentAssignProductsProduct;
  viewProduct: () => void;
  redirectToShop?: boolean;
  variant?: 'summary';
}

function ProductCard({ product, viewProduct, redirectToShop, variant }: CardProps): ReactElement {
  const $_ref = useRef<HTMLDivElement>();
  const classes = useProductStyles({ variant });

  const { shopify_data } = product;
  const { images, title } = shopify_data;
  const image = head(images);

  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const _set = (i: number) => {
      if (i > 100) return setWidth(200);

      const width =
        $_ref.current && typeof $_ref.current === 'object' ? $_ref.current.offsetWidth : undefined;
      if (!width) setTimeout(() => _set(i++), 100);
      else setWidth(variant === 'summary' ? Math.min(width, 300) : width - 4 * 16);
    };
    _set(0);
    window.addEventListener('resize', () => _set(0), false);

    return () => window.removeEventListener('resize', () => _set(0), false);
  });

  return (
    <div className={classes.root} ref={$_ref}>
      {image.src && width && (
        <Image
          src={image.src}
          layout="fixed"
          objectFit="contain"
          width={width}
          height={width}
          alt={image.altText || 'Reference image of product'}
        />
      )}
      <Typography variant="body1" className={classes.title} align="center">
        {title}
      </Typography>
      {redirectToShop ? (
        <Link href="/shop" passHref>
          <Button color="primary" variant="contained">
            View
          </Button>
        </Link>
      ) : (
        <Button color="primary" variant="contained" onClick={viewProduct}>
          View Product
        </Button>
      )}
    </div>
  );
}

const useProductDetailsStyles = makeStyles(theme => ({
  root: {
    padding: '0 3rem 3rem 3rem',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  image: {
    position: 'relative',
    width: '33%',
    height: '70vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '200px',
      marginBottom: '1rem',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  title: {
    fontWeight: 700,
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  price: {
    fontSize: '110%',
    marginBottom: '1rem',
  },
  button: {
    marginBottom: '1rem',
  },
}));

interface DetailsProps {
  product: CurrentAssignProductsProduct;
  handleAddToCart: (p: CurrentAssignProductsProduct) => void;
}

function ProductDetails({ product, handleAddToCart }: DetailsProps): ReactElement {
  const classes = useProductDetailsStyles();

  if (!product) return <div></div>;

  const { shopify_data } = product;
  const { images, price, descriptionHtml, title } = shopify_data;
  const image = head(images);

  return (
    <div className={classes.root}>
      {image.src && (
        <div className={classes.image}>
          <Image
            src={image.src}
            layout="fill"
            objectFit="contain"
            objectPosition="top"
            alt={image.altText || 'Reference image of product'}
            sizes="(max-width: 959.95px) 100vw, 33vw"
          />
        </div>
      )}
      <div className={classes.content}>
        <Typography variant="body1" className={classes.title} align="center">
          {title}
        </Typography>
        <Typography variant="body1" className={classes.price} align="center">
          {formatter.format(Number(price))}
        </Typography>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => handleAddToCart(product)}
        >
          Add To Cart
        </Button>

        <span dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      </div>
    </div>
  );
}
